import React, { useState, useEffect } from "react";
import "./component_css/StepRegistration.css";
import { put, ApiError } from "@aws-amplify/api";
import "particles.js";
import { toast } from "react-toastify";
import { faL } from "@fortawesome/free-solid-svg-icons";

const RegistrationForm = ({ buttonLabel = "Register Team", modalTitle = "Register Team" }) => {
  const initialFormData = {
    coachEmail: "",
    coachFName: "",
    coachLName: "",
    teamName: "",
    isCoach: true,
    numStudents: "",
    studentEmails: [],
    studentFNames: [],
    studentLNames: [],
    academicLevel: "",
    competitionLevel: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [currentStep, setCurrentStep] = useState(0);
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const [userAccepted, setUserAccepted] = React.useState(false);
  const [linkClicked, setLinkClicked] = React.useState(false);
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const closeModal = () => {
    setError("");
    setIsModalOpen(false);
    setCurrentStep(0);
    setFormData(initialFormData);
    setUserAccepted(false);
    setLinkClicked(false);
  }

  const handleUserChange = (e) => {
    setUserAccepted(e.target.checked);
  };

  useEffect(() => {
    // Validate fields for the current step
    const validateCurrentStep = () => {
      switch (currentStep) {
        // case 0:
        //   return true;
        case 0:
          return formData.isCoach ? formData.coachEmail.trim() !== "" && formData.coachFName.trim() !== "" && formData.coachLName.trim() !== "" : true;
        case 1:
          return formData.teamName.trim() !== "";
        case 2:
          return formData.academicLevel.trim() !== "";
        case 3:
          return formData.competitionLevel.trim() !== "";
        case 4:
          return formData.numStudents > 0;
        default:
          if (currentStep >= 5 && currentStep < 5 + formData.numStudents) {
            const index = currentStep - 5;
            return (
              formData.studentEmails[index]?.trim() !== "" &&
              formData.studentFNames[index]?.trim() !== "" &&
              formData.studentLNames[index]?.trim() !== ""
            );
          }
          return true;
      }
    };
    setIsNextEnabled(validateCurrentStep());
  }, [currentStep, formData]);

  const steps = [
    () => (
      <div>
        <h2>Coach must be 18+ years old.</h2>
        <label>Coach/Captain Email:</label>
        <input
          type="email"
          name="coachEmail"
          value={formData.coachEmail}
          onChange={(e) => setFormData({ ...formData, coachEmail: e.target.value })}
          required
        />
        <label>Coach/Captain First Name:</label>
        <input
          type="text"
          name="coachFName"
          value={formData.coachFName}
          onChange={(e) => setFormData({ ...formData, coachFName: e.target.value })}
          required
        />
        <label>Coach/Captain Last Name:</label>
        <input
          type="text"
          name="coachLName"
          value={formData.coachLName}
          onChange={(e) => setFormData({ ...formData, coachLName: e.target.value })}
          required
        />
      </div>
    ),
    () => (
      <div>
        <label>Team Name:</label>
        <input
          type="text"
          name="teamName"
          value={formData.teamName}
          onChange={(e) => setFormData({ ...formData, teamName: e.target.value })}
          required
        />
      </div>
    ),
    () => (
      <div>
        <label>Academic Level:</label>
        <div>
          {["High School", "Middle School"].map((level) => (
            <button
              key={level}
              type="button"
              className={formData.academicLevel === level ? "selected" : ""}
              onClick={() => setFormData({ ...formData, academicLevel: level })}
            >
              {level}
            </button>
          ))}
        </div>
      </div>
    ),
    () => (
      <div>
        <label>Competition Level:</label>
        <div>
          {["Beginner", "Advanced"].map((level) => (
            <button
              key={level}
              type="button"
              className={formData.competitionLevel === level ? "selected" : ""}
              onClick={() => setFormData({ ...formData, competitionLevel: level })}
            >
              {level}
            </button>
          ))}
        </div>
      </div>
    ),
    () => (
      <div>
        <label>Number of Players:</label>
        <div>
          {[3, 4, 5, 6].map((num) => (
            <button
              key={num}
              type="button"
              className={formData.numStudents === num ? "selected" : ""}
              onClick={() => setFormData({
                ...formData,
                numStudents: num,
                studentEmails: Array(num).fill(""),
                studentFNames: Array(num).fill(""),
                studentLNames: Array(num).fill(""),
              })}
            >
              {num}
            </button>
          ))}
        </div>
      </div>
    ),
    ...Array.from({ length: formData.numStudents }, (_, index) => () => (
      <div key={index}>
        <label>Player {index + 1} Email:</label>
        <input
          type="email"
          name="studentEmail"
          value={formData.studentEmails[index] || ""}
          onChange={(e) => {
            const updatedEmails = [...formData.studentEmails];
            updatedEmails[index] = e.target.value;
            setFormData({ ...formData, studentEmails: updatedEmails });
          }}
          required
        />
        <label>Player {index + 1} First Name:</label>
        <input
          type="text"
          value={formData.studentFNames[index] || ""}
          onChange={(e) => {
            const updatedFNames = [...formData.studentFNames];
            updatedFNames[index] = e.target.value;
            setFormData({ ...formData, studentFNames: updatedFNames });
          }}
          required
        />
        <label>Player {index + 1} Last Name:</label>
        <input
          type="text"
          value={formData.studentLNames[index] || ""}
          onChange={(e) => {
            const updatedLNames = [...formData.studentLNames];
            updatedLNames[index] = e.target.value;
            setFormData({ ...formData, studentLNames: updatedLNames });
          }}
          required
        />
      </div>
    )),
    () => (
      <div className="review-modal">
        <h3 className="modal-title">Review and Confirm:</h3>
        <form onSubmit={handleSubmit} className="modal-form">
          <div className="section">
            {/* <div className="coachCheck">
              <label>Register with Coach/Captain?</label>
              <input
                type="checkbox"
                name="isCoach"
                checked={formData.isCoach}
                onChange={(e) => setFormData({ ...formData, isCoach: e.target.checked })}
              />
            </div> */}

            {formData.isCoach && (
              <div className="coach-info">
                <h2>Coach must be 18+ years old.</h2>
                <label>Coach/Captain Email:</label>
                <input
                  type="email"
                  name="coachEmail"
                  className="input-field"
                  value={formData.coachEmail}
                  onChange={(e) => setFormData({ ...formData, coachEmail: e.target.value })}
                  required
                />
                <label>Coach/Captain First Name:</label>
                <input
                  type="text"
                  name="coachFName"
                  className="input-field"
                  value={formData.coachFName}
                  onChange={(e) => setFormData({ ...formData, coachFName: e.target.value })}
                  required
                />
                <label>Coach/Captain Last Name:</label>
                <input
                  type="text"
                  name="coachLName"
                  className="input-field"
                  value={formData.coachLName}
                  onChange={(e) => setFormData({ ...formData, coachLName: e.target.value })}
                  required
                />
              </div>
            )}
          </div>

          <div className="section">
            <label>Team Name:</label>
            <input
              type="text"
              name="teamName"
              className="input-field"
              value={formData.teamName}
              onChange={(e) => setFormData({ ...formData, teamName: e.target.value })}
              required
            />

            <label>Academic Level:</label>
            <div className="level-buttons">
              {["High School", "Middle School"].map((level) => (
                <button
                  key={level}
                  type="button"
                  className={`level-button ${formData.academicLevel === level ? "selected" : ""}`}
                  onClick={() => setFormData({ ...formData, academicLevel: level })}
                >
                  {level}
                </button>
              ))}
            </div>

            <label>Competition Level:</label>
            <div className="level-buttons">
              {["Beginner", "Advanced"].map((level) => (
                <button
                  key={level}
                  type="button"
                  className={`level-button ${formData.competitionLevel === level ? "selected" : ""}`}
                  onClick={() => setFormData({ ...formData, competitionLevel: level })}
                >
                  {level}
                </button>
              ))}
            </div>

            <label>Number of Players:</label>
            <div className="student-buttons">
              {[3, 4, 5, 6].map((num) => (
                <button
                  key={num}
                  type="button"
                  className={`student-button ${formData.numStudents === num ? "selected" : ""}`}
                  onClick={() =>
                    setFormData({
                      ...formData,
                      numStudents: num,
                      studentEmails: Array(num).fill(""),
                      studentFNames: Array(num).fill(""),
                      studentLNames: Array(num).fill(""),
                    })
                  }
                >
                  {num}
                </button>
              ))}
            </div>
          </div>

          <div className="section">
            {formData.numStudents > 0 && (
              <div className="students-info">
                {formData.studentEmails.map((_, index) => (
                  <div key={index} className="student-entry">
                    <label>Player {index + 1} Email:</label>
                    <input
                      type="email"
                      className="input-field"
                      value={formData.studentEmails[index] || ""}
                      onChange={(e) => {
                        const updatedEmails = [...formData.studentEmails];
                        updatedEmails[index] = e.target.value;
                        setFormData({ ...formData, studentEmails: updatedEmails });
                      }}
                      required
                    />
                    <label>Player {index + 1} First Name:</label>
                    <input
                      type="text"
                      className="input-field"
                      value={formData.studentFNames[index] || ""}
                      onChange={(e) => {
                        const updatedFNames = [...formData.studentFNames];
                        updatedFNames[index] = e.target.value;
                        setFormData({ ...formData, studentFNames: updatedFNames });
                      }}
                      required
                    />
                    <label>Player {index + 1} Last Name:</label>
                    <input
                      type="text"
                      className="input-field"
                      value={formData.studentLNames[index] || ""}
                      onChange={(e) => {
                        const updatedLNames = [...formData.studentLNames];
                        updatedLNames[index] = e.target.value;
                        setFormData({ ...formData, studentLNames: updatedLNames });
                      }}
                      required
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="section">
            <div className="accept">
              <input
                type="checkbox"
                id="acceptUserAgreement"
                onChange={handleUserChange}
                checked={userAccepted}
              />
              <p>To review and accept the User Agreement, please click <a href="https://cci.calpoly.edu/sites/default/files/2025-02/SGCRulesofConduct2025.pdf" target="_blank" rel="noopener noreferrer" onClick={() => setLinkClicked(true)} >here</a></p>
            </div>
          </div>
          {/* <div className="warning">
            <p className="warning">Please only click Submit once, it takes about a minute to register successfully. Wait for success.</p>
          </div> */}
        </form>
      </div>
    ),
  ].filter(Boolean);

  const handleSubmit = async (e) => {
    setSubmitLoading(true);
    e.preventDefault();

    if (!linkClicked) {
      setError("Please click the link to review Rules of Conduct before continuing");
      setSubmitLoading(false);
      return;
    }

    const formTemplate = {
      AWSRegion: "us-east-1",
      TeamName: formData.teamName,
      HowManyStudentsAreInYourTeam: formData.numStudents,
      AcademicLevel: formData.academicLevel,
      CompetitionLevel: formData.competitionLevel,
      AreYouRegisteringWithACoach: formData.isCoach,
      Coach: {
        Email: formData.isCoach ? formData.coachEmail : "",
        FName: formData.isCoach ? formData.coachFName : "",
        LName: formData.isCoach ? formData.coachLName : "",
      },

      ...Array.from({ length: formData.numStudents }, (_, index) => {
        const playerIndex = index + 1; // Player index starts from 1
        return {
          [`Player${playerIndex}`]: {
            Email: formData.studentEmails[index] || "",
            FName: formData.studentFNames[index] || "",
            LName: formData.studentLNames[index] || "",
          },
        };
      }).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
    };

    console.log(formTemplate)

    try {
      const restOperation = await put({
        apiName: "register",
        path: '/',
        options: {
          body: formTemplate
        }
      })

      const response = await restOperation.response;
      toast("Registration Successful! Users can now Sign In", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
      closeModal();
    } catch (err) {
      console.error(err)
      if (err instanceof ApiError) {
        setError(err.response?.body || "An error occurred.");
      } else {
        setError("An unknown error occurred.");
      }
    }
    setSubmitLoading(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      window.particlesJS.load("particles-js", "/particlesjs-config.json", function () {
        console.log("Particles.js config loaded");
      });
    }
  }, [isModalOpen]);

  return (
    <>
      <button onClick={() => setIsModalOpen(true)}>{buttonLabel}</button>

      {isModalOpen && (
        <div className="modal">
          <div id="particles-js"></div>
          <div className="modal-content">
            <span className="close"
              onClick={closeModal}>
              &times;
            </span>
            <div className="banner">
              <h2>{modalTitle}</h2>
            </div>
            <div className="stepForm">
              {steps[currentStep]()}

              {error && <p className="error">{error}</p>}
              <div className="navigation-buttons">
                {currentStep > 0 && (
                  <button type="button"
                    onClick={() => {
                      let backStep = currentStep - 1;
                      setError("");
                      setCurrentStep(backStep)
                    }}>
                    Back
                  </button>
                )}
                {currentStep < steps.length - 1 ? (
                  <button
                    type="button"
                    disabled={!isNextEnabled}
                    onClick={() => {
                      if (currentStep == 0) {
                        const coachEmailInput = document.querySelector('input[name="coachEmail"]');
                        if (!coachEmailInput.checkValidity()) {
                          setError("Please type email in the format: <user>@<domain>.com");
                          return;
                        }
                      } else if (currentStep >= 5) {
                        var studentEmailInput = document.querySelector('input[name="studentEmail"]');
                        if (!studentEmailInput.checkValidity()) {
                          setError("Please type email in the format: <user>@<domain>.com");
                          return;
                        }
                      }

                      let nextStep = currentStep + 1;
                      setError("");
                      setCurrentStep(nextStep);
                    }}
                  >
                    Next
                  </button>
                ) : (
                  <button type="submit" disabled={!userAccepted || submitLoading} onClick={handleSubmit}>{submitLoading ? "Loading..." : "Submit"}</button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RegistrationForm;
