// staging
// const aws_exports = {
//   Auth: {
//     Cognito: {
//       region: "us-east-1",
//       userPoolId: "us-east-1_K5Jj9uOrk",
//       userPoolClientId: "7fd8vg9bp4h56tidfit1nbn1gk",
//       identityPoolId: "us-east-1:626901dd-7edb-4145-bdaf-e2956b212bc6",
//     },
//   },
//   API: {
//     REST: {
//       "code": {
//         endpoint: "https://x0yyo8qet3.execute-api.us-east-1.amazonaws.com/PROD/",
//         region: "us-east-1"
//       },
//       "codev2": {
//         endpoint: "https://6yb4syim25.execute-api.us-east-1.amazonaws.com/PROD/",
//         region: "us-east-1"
//       },
//       "register": {
//         endpoint: "https://u2mj8o4pwa.execute-api.us-east-1.amazonaws.com/PROD/",
//         region: "us-east-1"
//       }
//     }
//   },
//   Websocket: "wss://3e1h1fqke7.execute-api.us-east-1.amazonaws.com/PROD/"
// };
// export default aws_exports;

// dev
const aws_exports = {
    Auth: {
        Cognito: {
            region: "us-east-1",
            userPoolId: "us-east-1_Ir1tIdmDx",
            userPoolClientId: "2n1fi4h4mjv4tgtschfhcj42lg",
            identityPoolId: "us-east-1:0e80f24e-6f9d-466e-b494-498da827592e",
        },
    },
    API: {
        REST: {
            "code": {
                endpoint: "https://v76r6by0oc.execute-api.us-east-1.amazonaws.com/PROD/",
                region: "us-east-1"
            },
            "codev2": {
                endpoint: "https://g8b02i607j.execute-api.us-east-1.amazonaws.com/PROD/",
                region: "us-east-1"
            },
            "register": {
                endpoint: "https://2sfyasca80.execute-api.us-east-1.amazonaws.com/PROD/",
                region: "us-east-1"
            }
        }
    },
    Websocket: "wss://r49pkt1u13.execute-api.us-east-1.amazonaws.com/PROD/"
};
export default aws_exports;