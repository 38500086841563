import React, { useState } from "react";
import { signIn, confirmSignIn } from "aws-amplify/auth";
import { Heading, Input, Button, Text } from "@aws-amplify/ui-react"
import Box from "@mui/material/Box"
import { Form, useNavigate } from "react-router-dom";

const SignInForm = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState("EMAIL"); // Stages: 'EMAIL', 'OTP', 'SUCCESS'
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleEmailSubmit = async () => {
    setLoading(true);
    try {
      const { nextStep } = await signIn({
        username: email,
        options: {
          authFlowType: "USER_AUTH",
          preferredChallenge: "EMAIL_OTP",
        },
      });

      if (nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_EMAIL_CODE") {
        setLoading(false);
        setStep("OTP");
        setErrorMessage("");
      } else {
        setLoading(false);
        setStep("EMAIL");
        setErrorMessage("EMAIL OTP UNAVAILABLE");
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage("Failed to send OTP. Please try again.");
      console.error("Error initiating sign-in:", error);
    }
  };

  const handleOtpSubmit = async () => {
    setLoading(true);
    try {
      const { nextStep } = await confirmSignIn({
        challengeResponse: otp,
      });

      if (nextStep.signInStep === "DONE") {
        setLoading(false);
        setStep("SUCCESS");
        setErrorMessage("");
        window.location.reload();
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage("Invalid OTP. Please try again.");
      console.error("Error confirming OTP:", error);
    }
  };

  return (
    <>
      <Box
        width="450px" // Increased width
        margin="auto"
        padding="3rem" // Increased padding
        border="3px solid var(--calpoly-green)" // Thicker border for emphasis
        borderRadius="12px" // Slightly larger border radius for a smoother look
        boxShadow="0 6px 12px rgba(0, 0, 0, 0.3)" // Enhanced shadow for better visibility
        textAlign="center"
        marginBottom="2.5rem" // Adjusted margin
        backgroundColor="#f5f5f5" // Slightly different shade for contrast
      >
        {step === "EMAIL" && (
          <Box>
            <Heading
              level={2}
              marginBottom="1.5rem"
              color="var(--calpoly-green)"
            >
              Welcome to SGC! 
            </Heading>
            <Text marginBottom="1.2rem" fontSize="1rem" color="#444">
              You will recieve an email from cci@calpoly.edu containing an OTP registration code to help you gain access to gameplay.
              Please enter your email below to receive a one-time passcode (OTP).
            </Text>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleEmailSubmit();
              }}
            >
              <Input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                marginBottom="1.2rem"
                padding="0.75rem"
                fontSize="1rem"
                backgroundColor="#fff"
                border="2px solid var(--calpoly-green)"
                borderRadius="6px"
              />
              <Button
                type="submit"
                variation="primary"
                fullWidth
                style={{
                  backgroundColor: "var(--calpoly-green)",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                  padding: "0.75rem",
                }}
              >
                {loading ? "Loading..." : "Send OTP"}
              </Button>
            </form>
            {errorMessage && <Text color="red" fontSize="1rem">{errorMessage}</Text>}
          </Box>
        )}

        {step === "OTP" && (
          <Box>
            <Heading
              level={2}
              marginBottom="1.5rem"
              color="var(--calpoly-green)"
            >
              Verify Your Email
            </Heading>
            <Text marginBottom="1.2rem" fontSize="1rem" color="#444">
              Enter the OTP sent to your email to continue.
            </Text>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleOtpSubmit();
              }}
            >
              <Input
                type="text"
                placeholder="Enter your OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                marginBottom="1.2rem"
                padding="0.75rem"
                fontSize="1rem"
                backgroundColor="#fff"
                border="2px solid var(--calpoly-green)"
                borderRadius="6px"
              />
              <Button
                type="submit"
                variation="primary"
                fullWidth
                style={{
                  backgroundColor: "var(--calpoly-green)",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                  padding: "0.75rem",
                }}
              >
                {loading ? "Loading..." : "Verify OTP"}
              </Button>
            </form>
            {errorMessage && <Text color="red" fontSize="1rem">{errorMessage}</Text>}
          </Box>
        )}

        {step === "SUCCESS" && (
          <Box>
            <Heading
              level={3}
              color="#154734"
              marginBottom="1.5rem"
            >
              Success!
            </Heading>
            <Text color="#444" marginBottom="1.2rem" fontSize="1.1rem">
              You have successfully signed in.
            </Text>
            <Text fontWeight="bold" color="#154734" fontSize="1.2rem">
              Welcome to the California Cybersecurity Institute.
            </Text>
          </Box>
        )}
      </Box>
    </>
  );
};

export default SignInForm;